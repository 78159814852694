import { GridDataProps } from 'hooks/useGrid/gridFunctionsMain';
import { getAllCostCentresInUnit } from 'hooks/useGrid/gridFunctionsShared';
import { useCallback, useEffect, useRef } from 'react';
import { API } from 'services';
import { TimeSelection } from 'types/appContext';
import { DATE_FORMAT_STANDARD, DateHelper, generateTimeArray } from 'utils/date';
import { createAnalyticsWorker } from 'workers/analytics/createAnalyticsWorker';
import { debounce } from 'lodash';
import { NameId } from 'types/generic';
import useAuth from './useAuth';
import useData from './useData';

type PartiallyForceDefined<T extends Object, K extends keyof T> = {
    [P in keyof T]: P extends K ? NonNullable<T[P]> : T[P];
};

export type AnalyticsMessage = {
    currentUnitType: string;
    currentCountry: string;
} & PartiallyForceDefined<GridDataProps, 'currentUnit' | 'currentScenario' | 'unfilteredCountryOMData' | 'user' | 'config' | 'coworkers'>;

export type AnalyticsData = {
    scenarioId: string;
    costCentreData: Array<{
        costCentreId: string;
        divisions: Array<NameId>
        weeklyData: Array<{
            startDate: string; // YYYY-MM-DD
            endDate: string; // YYYY-MM-DD
            calculations: {
                targetCapacityHours: number;
                currentCapacityHours: number;
                vacationHours: number;
                sicknessHours: number;
                absenceHours: number;
                gapHours: number;
            };
        }>;
    }>
};

export type AnalyticsWorkerResponse = { type: 'failed' } | {
    type: 'result',
    data: AnalyticsData,
    country: string,
    unitCode: string,
    unitType: string,
};

const generateTimeSelection = (startWeek: string, endWeek: string): TimeSelection => {
    const startDate = DateHelper.getISOWeekStartDate(startWeek).format(DATE_FORMAT_STANDARD);
    const endDate = DateHelper.getISOWeekEndDate(endWeek).format(DATE_FORMAT_STANDARD);

    return {
        selection: 'week',
        valid: true,
        errorMessage: null,
        startDate,
        endDate,
        timeArray: generateTimeArray(startDate, endDate, 'week'),
    };
};

export const useGapAnalytics = () => {
    const workerRef = useRef<Worker | null>(null);
    const { user, access } = useAuth();
    const { currentCountry, currentUnit, currentUnitType, scenarioList, config, unfilteredCountryOMData, coworkerData } = useData();

    // This is for the debounce - useCallback and debounce does not play well together
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleAnalytics = useCallback(
        debounce(() => {
            if (
                !currentCountry
            || !currentUnit
            || !currentUnitType
            || !unfilteredCountryOMData
            || !user
            || !config
            || !coworkerData
            ) {
                return;
            }
            const coworkers = coworkerData;
            // Generate requred data

            // TODO - Make the start and end week dynamic when we get info on how it should be done. (E.g. current and next FY)
            const timeSelection = generateTimeSelection('2023-W01', '2025-W52');
            const hasModulationAccess = false;
            const costCentreList = getAllCostCentresInUnit(unfilteredCountryOMData, currentUnit);

            // Fetch all required BE data
            if (access?.api?.readInputs && access?.api?.readScenarios && access?.api?.readScenariosList) {
                const pUnitBudget = API().getBudget(
                    currentCountry,
                    currentUnit,
                    currentUnitType,
                    timeSelection.startDate,
                    timeSelection.endDate,
                );
                const pUnitRates = API().getRates(
                    currentCountry,
                    currentUnit,
                    currentUnitType,
                    timeSelection.startDate,
                    timeSelection.endDate,
                );

                const pMainScenario = API()
                    .getScenarios(currentCountry, currentUnit, currentUnitType)
                    .then(res => {
                        const mainScenarioId = res?.scenarios?.find(({ isMain }) => isMain)?.id;

                        if (!mainScenarioId) return Promise.reject();

                        return API().getScenario(mainScenarioId, currentCountry, currentUnit, currentUnitType);
                    });
                Promise.all([pUnitBudget, pUnitRates, pMainScenario]).then(([currentUnitBudget, currentUnitRates, currentScenario]) => {
                    // Collect all data
                    const postData: AnalyticsMessage = {
                        user,
                        timeSelection,
                        hasModulationAccess,
                        currentUnit,
                        currentUnitType,
                        currentScenario,
                        currentCountry,
                        currentUnitBudget,
                        currentUnitRates: currentUnitRates?.data,
                        workload: undefined, // Leave as undefined (does not impact hours)
                        storedNoScenarioData: undefined, // always undefined for worker
                        costCentreList,
                        coworkers,
                        config,
                        unfilteredCountryOMData,
                        customContributions: undefined, // Leave as undefined (does not impact hours)
                        defaultContributions: undefined, // Leave as undefined (does not impact hours)
                    };

                    workerRef.current?.postMessage(postData);
                }).catch(() => {
                    // Do nothing for now
                });
            }
        }, 15_000),
        [
            config,
            coworkerData,
            currentCountry,
            scenarioList,
            currentUnit,
            currentUnitType,
            unfilteredCountryOMData,
            user
        ]
    );

    useEffect(() => {
        workerRef.current = createAnalyticsWorker();

        if (workerRef.current) {
            workerRef.current.onmessage = (
                event: MessageEvent<AnalyticsWorkerResponse>,
            ) => {
                if (event.data.type === 'failed') {
                    // Failed to get data - Do nothing for now
                    return;
                }
                if (event.data.type === 'result') {
                    const { data, country, unitCode, unitType } = event.data;
                    API().postGapAnalytics(country, unitCode, unitType, data).then(res => {
                        if (res?.ok) {
                            // Success - Do nothing for now
                        } else {
                            // Failed posting - Do nothing for now
                        }
                    }).catch(() => {
                        // Unknown error - Do nothing for now
                    });
                }
            };
            workerRef.current.onerror = () => {
                // Worker error handling can go here if needed.
            };
        }

        return () => {
            workerRef.current?.terminate();
            workerRef.current = null;
        };
    }, []);

    return { handleAnalytics };
};
